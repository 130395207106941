<template>
	<div>
		<ContentHeader title="Laporan Pelayanan Klinik" subTitle="Laporan Diagnosa" url="laporan-pelayanan-klinik" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Data Laporan Diagnosa</h3>
                    </div>
                    <div class="card-body" style="display: block;">

                        <div class="row d-flex justify-content-end">
						<el-button @click="handleExportExcel" :loading="loadingExcel">Export Excel</el-button>	
						</div>

                        <div class="row" v-if="toogleCabang">
                        <div class="col-lg-4">
                            <label>Pilih Cabang</label>
                            <multiselect 
                            v-model="cabang_select_value"
                            deselect-label="Can't remove this value"
                            track-by="nama"
                            label="nama"
                            placeholder=""
                            :options="cabang"
                            :loading="loading_cabang"
                            :allow-empty="false"
                            @update:model-value="getDataResult"
                            >
                            </multiselect>
                        </div>
                        </div>
                        <div class="row align-items-center mt-2">
                            <div class="col-lg-2">
                            <span>Jenis Periode</span>
                            <select class="custom-select form-control" v-model="jenis_periode" @change="getDataResult">
                            <option value="tanggal">Tanggal</option>
                            <!-- <option value="minggu">Minggu</option> -->
                            <option value="bulan">Bulan</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <span>Tanggal</span>
                            <input type="date" name="" v-model="tanggal" class="form-control" @change="getDataResult">
                        </div>
                        <div class="col-lg-4">
                        </div>
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" class="text-sm align-middle text-center" rowspan="3">NO</th>
                                <th scope="col" class="text-sm align-middle text-center" rowspan="3">DIAGNOSA</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="24">JUMLAH KASUS MENURUT GOLONGAN UMUR</th>
                                <th scope="col" class="text-sm align-middle text-center" rowspan="3">TOTAL</th>
                            </tr>
                            <tr>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">0-7 Hari</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">8-29 Hari</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">29 Hr-1 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">1-4 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">5-9 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">10-14 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">15-19 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">20-44 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">45-54 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">55-59 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">60-69 Th</th>
                                <th scope="col" class="text-sm align-middle text-center" colspan="2">>= 70 Th</th>
                            </tr>
                            <tr>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                                <th scope="col" class="text-sm align-middle text-center">L</th>
                                <th scope="col" class="text-sm align-middle text-center">P</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in data_result" :key="row.id">
                                <td class="text-sm align-middle text-center" colspan="1">{{ index + 1 }}</td>
                                <td class="text-sm" colspan="1">{{ row.kode }} - {{ row.diagnosa }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.satu_sampai_tujuh_hari_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.satu_sampai_tujuh_hari_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.delapan_sampai_duasembilan_hari_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.delapan_sampai_duasembilan_hari_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.tigapuluh_hari_sampai_satu_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.tigapuluh_hari_sampai_satu_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.satu_sampai_empat_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.satu_sampai_empat_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.lima_sampai_sembilan_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.lima_sampai_sembilan_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.sepuluh_sampai_empatbelas_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.sepuluh_sampai_empatbelas_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.limabelas_sampai_sembilanbelas_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.limabelas_sampai_sembilanbelas_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.duapuluh_sampai_empatpuluhempat_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.duapuluh_sampai_empatpuluhempat_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.empatpuluhlima_sampai_limapuluhempat_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.empatpuluhlima_sampai_limapuluhempat_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.limapuluhlima_sampai_limapuluhsembilan_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.limapuluhlima_sampai_limapuluhsembilan_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.enampuluh_sampai_enampuluhsembilan_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.enampuluh_sampai_enampuluhsembilan_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.diatas_tujuhpuluh_tahun_laki_laki }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.diatas_tujuhpuluh_tahun_perempuan }}</td>
                                <td class="text-sm align-middle text-center" colspan="1">{{ row.total }}</td>
                            </tr>
                        </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const total = ref('')
const cek_data = ref(false)
const memuat_data = ref(false)
const tanggal = ref('')
const jenis_periode = ref('tanggal')

const getDataResult = async () => {
    console.log(jenis_periode.value)
    data_result.value = []
    total.value = ''
    memuat_data.value = true
    cek_data.value = false
    let date = ''
    let cabang_id = ''

    if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
    } else {
        cabang_id = cabang_select_value.value.id
    }

    if (tanggal.value == '') {
        date = 'emptyParameter'
    } else {
        date = tanggal.value
    }

    let { data } = await axios.get(`api/laporan/getLaporanDiagnosa/${cabang_id}/${jenis_periode.value}/${date}`)
    if (data != 'kosong') {
        memuat_data.value = false
        cek_data.value = false
        data_result.value = data
    } else {
        cek_data.value = true
        memuat_data.value = false
        data_result.value = []
    }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
    let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

    if (response.data.jenis == 'pusat') {
        toogleCabang.value = true
        let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
        if (data != 'kosong') {
            cabang.value = data
            loading_cabang.value = false
        } else {
            cabang.value = []
            loading_cabang.value = false
        }
    }
}

// laporan diagnosa Export
// const loadingExcel = ref(false)
// 	const handleExportExcel = async () => {
// 		loadingExcel.value = true

// 		let parameter = ''
// 		if (kata_kunci.value != '') {
// 			parameter = kata_kunci.value
// 		} else {
// 			parameter = 'emptyParameter'
// 		}

// 		let lokasi = ''
// 		if (filter_lokasi.value == '') {
// 			lokasi = 'semua'
// 		} else {
// 			lokasi = filter_lokasi.value.id
// 		}
		
// 		await axios.get(`api/export/stok_produk/${user.value.cabang_id}/obat/${parameter}/${lokasi}/${tipe_obat.value ? tipe_obat.value : 'emptyParameter'}/${cabang.value.tipe}`, { responseType: 'blob' })
// 		.then((response) => {
// 			console.log(response)
// 			let blob        = new Blob([response.data],{ type: 'application/vnd.ms-excel;charset=utf-8' })
// 			let url         = window.URL || window.webkitURL;
// 			let fileUrl     = url.createObjectURL(blob)
// 			let element     = document.createElement('a')
// 			element.href    = fileUrl;
// 			element.setAttribute('download',`laporan-stok-produk.xlsx`)
// 			document.body.appendChild(element)
// 			element.click()
// 			window.URL.revokeObjectURL(blob)
// 		})
// 		.catch(() => {
// 			Swal.fire({
// 			title: 'Gagal!',
// 			text: 'Gagal mengeksport data',
// 			icon: 'error',
// 			showConfirmButton: false,
// 			timer: 1500
// 			})	
// 		})
// 		.finally(() => {
// 			loadingExcel.value = false
// 		})
// 	}
// end laporan diagnosa Export


onMounted(() => {
    getCabang()
    getDataResult()
})
</script>